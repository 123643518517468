<template>
  <div class="zy-pie-table">
    <div class="zy-pie-table-chart">
      <div class="zy-pie-table-chart-pie">
        <zy-chart-ring
          :color="color"
          :data="pieList"
          :auto="[70, 90]"
        >
          <slot></slot>
        </zy-chart-ring>
      </div>
      <div class="zy-pie-table-chart-tips">
        <div
          class="zy-pie-table-chart-tips-item"
          v-for="(item, index) in list"
          :key="`tips_${index}`"
        >
          <div :style="{ background: color[index] }"></div>
          <div>{{ item.name }}</div>
        </div>
      </div>
    </div>
    <div class="zy-pie-table-list">
      <el-table
        :data="list"
        style="width: 100%"
        :header-cell-style="headerCellStyle"
        :header-row-style="headerRowStyle"
        :cell-style="cellStyle"
        :row-style="rowStyle"
      >
        <zy-pie-table-column
          :node="node"
          :index="index"
          :color="color"
          :row="list[index]"
          v-for="(node, index) in $slots.table"
          :key="index"
        ></zy-pie-table-column>
      </el-table>
      <el-table v-show="false">
        <slot name="table"></slot>
      </el-table>
    </div>
  </div>
</template>

<script>
import components from "@/view/jxyh/components"
import { color } from "echarts"
export default {
  ...components(["ZyChartRing"], {
    ZyPieTableColumn: {
      functional: true,
      render(h, ctx) {
        const { node, index, color } = ctx.props
        let propsData = node.componentOptions.propsData || {}
        const column = h("el-table-column", {
          props: propsData,
          scopedSlots: {
            default: (props) => {
              if (index == 0) {
                const tips = h("div", {
                  class: "table-tips",
                  style: {
                    background: color[props.$index],
                    borderRadius: "2px",
                  },
                })
                return h(
                  "div",
                  {
                    class: "table-cell",
                  },
                  [tips, props.row.name]
                )
              }
              let value = props.row[propsData.prop]
              if (node.data.scopedSlots && node.data.scopedSlots.default) {
                value = node.data.scopedSlots.default({ row: props.row || {} })
              }
              return [value]
            },
          },
        })
        return column
      },
    },
  }),
  props: {
    color: {
      type: Array,
      default: () => ["#FC4A66", "#007FF4", "#FFB822", "#62FF85", "#19FBFF"],
    },
    list: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    pieList() {
      const list = JSON.parse(JSON.stringify(this.list))
      list.forEach((row) => {
        row.value = Number(row.value)
      })
      return list
    },
  },
  methods: {
    headerCellStyle() {
      return {
        background: "#1E5087",
        borderBottom: "unset",
        fontWeight: "bold",
        fontSize: "14px",
        color: "#FFFFFF",
        lineHeight: "21px",
        padding: "12px 0 14px 0",
      }
    },
    headerRowStyle() {
      return {
        background: "#1E5087",
      }
    },
    rowStyle() {
      return {
        background: "transparent",
      }
    },
    cellStyle() {
      return {
        fontSize: "14px",
        color: "#FFFFFF",
        lineHeight: "21px",
        padding: "10px 0",
        background: "transparent",
        borderBottom: "rgba(255, 255, 255, 0.1) 1px solid",
      }
    },
  },
}
</script>

<style lang="less" scoped>
.zy-pie-table {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  &-chart {
    display: flex;
    flex-direction: column;
    &-pie {
      width: 160px;
      height: 160px;
    }
    &-tips {
      width: 160px;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      padding-left: 10px;
      &-item {
        margin-top: 16px;
        font-size: 12px;
        color: #ffffff;
        line-height: 18px;
        flex-shrink: 0;
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 50%;
        & > div:nth-child(1) {
          width: 10px;
          height: 10px;
          border-radius: 2px;
          margin-right: 6px;
        }
      }
    }
  }
  &-list {
    width: 324px;
  }
}
.table {
  &-tips {
    width: 10px;
    height: 10px;
    margin-right: 10px;
  }
  &-cell {
    display: flex;
    flex-direction: row;
    align-items: center;
    line-height: 21px;
  }
}
.el-table::before,
.el-table {
  background: transparent;
}
</style>
